import { getHotel } from "api/api";
import BottomNav from "components/bottomNav";
import { PAYMENT_DATA, UUID_KY } from "enumeration";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import { numberWithCommas } from "utils/calculators";
import { useDelivery } from "_context/delivery/deliveryProvider";
import { usePayment } from "_context/payment/paymentProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import animationData from "./animation.json";
import Lottie from "react-lottie";
import { Button } from "react-bootstrap";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import { useCategory } from "_context/category/provider";
import "./style.scss";
import styles from "./style.module.scss";
import anim from "./animation.gif";
import { useDiscount } from "_context/discount/discountProvider";

export default function Done() {
	const { t, i18n } = useTranslation();

	const { initial: dInitial } = useDelivery();
	const { initial: pInitial } = usePayment();
	const { initial: catInitial } = useCategory();
	const { initial: cInitial } = useCarts();
	const { setUserId, setLegalAge, initialVisibledPopup } = useUtmSource();
	const discountState = useDiscount();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	useEffect(() => {
		dInitial();
		pInitial();
		cInitial();
		catInitial();
		setUserId();
		setLegalAge(false);
		discountState.initial();
		initialVisibledPopup?.();
	}, []);

	return (
		<>
			<div className={styles["blank-page"]}>
				<img className={styles.image} src={anim} />
				{/* <Lottie options={defaultOptions} height={400} width={400} /> */}
				<p className={styles["text"]}>{t("preparing")}</p>

				<Link to={"/"}>
					<Button variant="outline-success">{t("back-to-menu")}</Button>
				</Link>
			</div>
		</>
	);
}
